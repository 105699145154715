/* AboutUs.css */

/* Hero section styles */
.hero-section {
    text-align: center;
    padding: 100px 0;
   /* background-color: #f8f8f8; 
    background-image: url('../assets/images/banners/banner3.jpg'); */
    background-size: cover;
    background-position: center;
    flex: 1;
    width: 100%;
    height: 100vh;
  
  }

  .hero-section h1{
    color:#fff;
    
  }
  .hero-section h1{
    font-size: 40px;
    margin-left: 0;
    margin-top: 30px;
    
  }

  

  .sub-title {
    font-size: 30px;
    color:#fff;
  
    
  }
  
  /* Our Story section styles */
  .our-story-section {
    padding: 50px 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .our-story-section p{
    font-size: 20px;
    
  }
  
  /* Mission & Vision section styles */
  .mission-vision-section {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
  }
  
  .mission-section,
  .vision-section {
    flex: 1;
  }
  
  .mission-content,
  .vision-content {
    padding: 20px;
    background-color: #fc0356; /* Background color for mission and vision content */
    color: #fff; /* Text color for mission and vision content */
  }
  
  
  