/* Define styles for the CTA button */
.cta-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #fc0356; /* Primary button color */
    color: #fff; /* Text color */
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow */
    font-weight: bold;
    transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
    cursor: pointer;
    margin-top: 30px;
  }
  
  /* Hover effect - change background color and add a slight scale effect */
  .cta-button:hover {
    background-color: #ff3f81; /* Hover button color */
    transform: scale(1.05);
  }
  
  /* Add more styles or customize as needed */
  