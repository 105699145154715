.back-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-color: #fc0356;
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    cursor: pointer;
    display: none;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease-in-out;
  }
  
  .back-to-top-button.visible {
    display: flex;
  }
  
  .back-to-top-button:hover {
    background-color: #d8004d; /* Change the color on hover */
  }
  