.our-services {
    text-align: center; /* Center the content horizontally */
    padding: 100px 0; /* Add more padding to create wider margins */
}

.our-services h2 {
    font-size: 30px; 
    color:#080736;
}

.our-services p {
    font-size: 20px; /* Adjust the font size as needed */
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px; /* Add some top margin for spacing */
}

/* Add your Services container styles */
.services-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1200px; /* Adjust the maximum width as needed */
    padding: 20px;
  }
  
  /* Style for individual service cards */
  .service-card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex: 1;
    margin: 20px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    width: calc(33.33% - 40px); /* Adjust the width as needed */
  }
  
  /* Style for the Learn More button */
  .learn-more-button {
    background-color: #fc0356;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    padding: 10px 20px;
    text-transform: uppercase;
    transition: background-color 0.3s ease-in-out;
  }
  
  .learn-more-button:hover {
    background-color: #fc0356;
  }
  
  /* Responsive styles for smaller screens */
  @media (max-width: 768px) {
    .service-card {
      width: calc(50% - 40px);
    }
  }
  
  @media (max-width: 480px) {
    .service-card {
      width: 100%;
    }
  }
  