/* AboutUs.css */

/* Hero section styles */
.about-hero-section {
  text-align: center;
  padding: 100px 0;
  background-color: #061247; /* Background color for the hero section */
 /* background-image: url('../assets/images/banners/banner5.jpg'); */
  background-size: cover;
  background-position: center;
  flex: 1;
  width: 100%;
  height: 100vh;

}