/* OurTeam.css */

/* Our Team section styles */
.our-team {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  /* Team card styles */
  .team-card {
    width: calc(33.33% - 20px); /* Three columns with some spacing */
    margin-bottom: 20px;
    position: relative;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); /* Blending shadow */

  }
  
  .team-card:hover {
    transform: scale(1.05); /* Scale up on hover */
  }
  
  /* Team avatar styles */
  .team-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }
  
  .team-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Team info styles */
  .team-info {
    text-align: center;
    padding: 10px;
  }
  
  .name {
    font-weight: bold;
  }
  
  /* Add more styles as needed */
  
  /* Styles for the expanded card on hover */
  .expanded-card {
    display: flex;
  }
  
  .expanded-image {
    flex: 1;
  }
  
  .about-me-title,
  .hobbies-title {
    font-weight: bold;
  }
  
  /* Additional styles for the expanded card */
  