/* NotFound.css */

.not-found {
    text-align: center;
    margin: 50px auto;
    padding: 20px;
    max-width: 500px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    color: #fc0356;
    font-size: 36px;
    margin-bottom: 10px;
    display: block;
  }
  
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .cta-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #fc0356;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background-color: #ff3f81;
  }
  
  /* You can add more custom styles as needed */
  