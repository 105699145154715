/* Portfolio.css */
.portfolio-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
  }
  
  .portfolio-slider {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .image-wrapper {
    overflow: hidden;
    margin: 0 20px;
  }
  
  .prev-button,
  .next-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
  
  .prev-button {
    left: 0;
  }
  
  .next-button {
    right: 0;
  }
  