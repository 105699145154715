/* Reset some default styles */
body, h1, h2,h3 p {
  margin: 30px;
  padding: 0;
  opacity: 0.9;
}

/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: #ece6e6;
}


/*Form */
.form {
  padding: 1rem;
}

.button {
  display: block;
  width: 100%;
  background-color: #e14;
  border: none;
  border-radius: 3px;
  padding: 1ch;
  font-size: 1rem;
  color: #fff;
  font-family: inherit;
}

input {
  display: block;
  width: 100%;
  border: 2px solid #deddef;
  padding: 1ch;
  border-radius: 3px;
  transition: 150ms ease;
  outline: none;
  font-size: 1rem;
  font-family: inherit;
}

 input-focus {
  border-color: #e14;
}

/* CSS for input fields when focused and hover styles */
.form-control:focus,
.form-control:hover
 {
  border-color: #e14 !important; 
}

/* CSS to remove Bootstrap's default hover effect */
.form-control:hover,.form-control:focus {
  background-color: transparent !important; /* Set the background to transparent to remove hover background color */
  color: #e14 !important; /* Set the text color to your preferred color */
}










label {

  padding: 0 .5ch;
  pointer-events: none;
  transition: 150ms ease;
  color: #888;
}

label-focus {

  font-size: 0.9em;
  color: #e14;
  font-weight: bold;
  transform: translateY(-50%);
  background-color: #fff;
  font-weight: 600;
}

/* CSS for textarea with grey border by default */
textarea {
  border: 1px solid #ccc;
  /* Add any additional styles you need for the textarea */
}

/* CSS for textarea when focused */
textarea:focus {
  border-color: #e14 !important; /* Add !important to override default styles */
}
.form-control:hover, .form-control:focus, .textarea:hover, .textarea:focus {
  background-color: transparent !important; /* Set the background to transparent to remove hover background color */
  color: #e14 !important; /* Set the text color to your preferred color */
}



/* Button */
.cta-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #fc0356;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: #fc0356;
}

.primary-text{
  color:#fc0356;
  font-weight: 300;
  text-align: left;
  margin: 10px;
  padding: 10px;
}
.left-text{
  text-align: left;
}
.box-icon{
  color:#fc0356;
}


/* Add more styles for feature, pricing, and contact sections as needed */

/* Footer styles */
/*footer {
  text-align: center;
  background-color: #333;
  color: #ffffff;
  padding: 20px 0;
  position: fixed;
  bottom: 0px;
  margin-bottom: 0px;
}*/

/* Basic CSS animation class */
.fade-in-up {
  opacity: 0.3;
  transform: translateY(20px); /* Adjust the initial vertical position */
  transition: opacity 0.5s ease, transform 0.5s ease; /* Customize animation duration and easing */
}

/* Add the "active" class to trigger the animation */
.fade-in-up.active {
  opacity: 1;
  transform: translateY(0); /* Return to the original position */
}
.section{
  width: 100%;
  height: auto;
  margin-top: 40px;
}


