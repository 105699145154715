/* PortfolioPage.css */
.portfolio-page{
  background-color: rgb(245, 248, 245);
  margin: 10px;
}

/* Top section styles */
.top-section {
    background-color: #f8f8f8;
    padding: 50px 0;
    text-align: center;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 60px;
  }
  
  /* Card section styles */
  .card-section {
    padding: 50px 0;
  }
  
  .portfolio-card {
    border: none;
    margin: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); /* Blending shadow */
  }
  
  .portfolio-card .card-title {
    font-weight: normal;
    margin-top: 10px;
  }

  .active {
    background-color: #fc0356; /* Set the background color for the active button */
    color: #fff; /* Set the text color for the active button */
    /* Add any other styles for the active button as needed */
  }
  
  