/* Content styling */
/* fonts for the text */
@import url('https://fonts.googleapis.com/css?family=Merriweather:700|Roboto');
@import url('https://fonts.googleapis.com/css?family=Anton|Roboto');







.card {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba(#124,.06);
  overflow: hidden;
}

.waves {
  height: 80px;
  background: linear-gradient(to bottom left, #e14, #e72);
}

h1 {
  margin: 2rem 2rem 0;
  font-size: 1.62rem;
}





