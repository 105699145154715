/* Header styles */
header {
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /*position: fixed;*/
    width: 100%;
    z-index: 1;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}



.logo img {
    max-height: 85px;
    max-width: 110;
    margin-left: 15px;
}
header, nav, ul {
    margin-right: 10px;
    margin-bottom: 0;
    padding: 0;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
}

.nav-links li a {
    text-decoration: none;
    color: #333;
    transition: color 0.3s ease-in-out;
}

/* Style for the active link */
.nav-links li a.active::after,
.nav-links li a:hover::after {
    content: "";
    display: block;
    height: 4px; /* Adjust the thickness of the underline */
    background-color: #fc0356; /* Adjust the color of the underline */
    margin-top: 2px; /* Adjust the margin between the text and underline */
}

.nav-links li a:hover {
    color: #fc0356;
}

/* Style the link text color for active links */
.nav-links li a.active {
    color: #fc0356; 
    background-color: transparent;
}

/* Style for the active link */
/*.nav-links li a.active::after {
    content: "";
    display: block;
    height: 4px; 
    background-color: #fc0356; 
    margin-top: 2px; 
  }*/

/* Hero section styles */
#hero{
    min-height: 400px;
    /*background: url('../../assets/images/banners/ban1.jpeg') no-repeat 0 -400px;*/
    background-color: #061247;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    text-align: center;
    align-items: center;
    color:#fff;
    margin:0;
}
.hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 100px 0;
}

.hero-text {
    flex: 1;
    padding: 0 20px;
}

.hero-detail {
    flex: 1;
    padding: 0 20px;
    font-size: 18px;
    color:#080736;
}

.hero h1 {
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 20px;
}

.hero p {
    font-size: 20px;
    color: #cac1c1;
    margin-bottom: 60px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .nav-links {
      flex-direction: column;
      gap: 10px;
      text-align: center;
    }
  }
