/* AboutUs.css */

/* Hero section styles */
.software-hero-section {
    text-align: center;
    padding: 100px 0;
    background-color: #061247;
    /*background-image: url('../assets/images/banners/banner2.jpg'); */
    background-size: cover;
    background-position: center;
    flex: 1;
    width: 100%;
    height: 100vh;
  
  }

  .software-hero-section h1{
    color:#fff;
    
  }
  .software-hero-section h1{
    font-size: 40px;
    margin-left: 0;
    margin-top: 30px;
    
  }

  /** Mobile**/
  .mobile-hero-section {
    text-align: center;
    padding: 100px 0;
    background-color: #f8f8f8; /* Background color for the hero section */
    background-image: url('../assets/images/banners/banner1.jpg'); 
    background-size: cover;
    background-position: center;
    flex: 1;
    width: 100%;
    height: 100vh;
  
  }

  .mobile-hero-section h1{
    color:#fff;
    
  }
  .mobile-hero-section h1{
    font-size: 40px;
    margin-left: 0;
    margin-top: 30px;
    
  }

  .sub-title {
    font-size: 30px;
    color:#fff;
  
    
  }
  
  
  