/* AboutUs.css */

/* Hero section styles */
.it-hero-section {
    text-align: center;
    padding: 100px 0;
    background-color: #f8f8f8; /* Background color for the hero section */
    background-image: url('../assets/images/banners/banner4.jpg'); 
    background-size: cover;
    background-position: center;
    flex: 1;
    width: 100%;
    height: 100vh;
  
  }

  .it-hero-section h1{
    color:#fff;
    
  }
  .it-hero-section h1{
    font-size: 40px;
    margin-left: 0;
    margin-top: 30px;
    
  }

  

  .sub-title {
    font-size: 30px;
    color:#fff;
  
    
  }
  
 
  
  